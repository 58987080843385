<template>
   <v-app id="title">
      <div class="transparent-layer"></div>
      <h1 class="text">Training Placement Opportunity</h1>
      <v-container>
         <v-row align="center" justify="center"> 
            <v-col cols="12" sm="12">
               <v-card  dense class="elevation-10 mt-6">
                  <v-row  dense> 
                     <v-col cols="12" sm="6">
                        <v-card-text class="mt-5">
                           <v-carousel height="auto" cycle hide-delimiter-background hide-delimiters-on-mobile :prev-icon="false" :next-icon="false" hide-delimiters>
                              <v-carousel-item v-for="(item, i) in logo" :key="i">
                              <center>
                              <v-img :src="item.logo_url" width="250"></v-img>
                              </center>
                              </v-carousel-item>
                           </v-carousel><br>
                           <h4 class="text-center custom-text-color">Login</h4>
                           <v-row align="center" justify="center">
                              <v-col cols="12" sm="8">
                                 <v-form>
                                    <!-- Email -->
                                    <v-text-field 
                                       v-model="user.uid" 
                                       placeholder="Enter Your Username"
                                       outlined dense 
                                       color="black"
                                       clearable 
                                       required>
                                    </v-text-field>
                                    <!-- Password -->
                                    <v-text-field 
                                       v-model="user.pass" 
                                       :rules="[v => !!v || 'password required']"
                                       :append-icon="value ? 'mdi-eye' : 'mid-eye-off'"
                                       :type="value ? 'password' : 'text'" 
                                       @click:append="() => (value = !value)"
                                       placeholder="Enter Your Password" 
                                       outlined dense 
                                       color="black" 
                                       required 
                                       clearable>
                                    </v-text-field>
                                    <!-- Login Button -->
                                    <v-btn class="logi" dark block tile :loading="loading" @click="signIn">Login
                                    </v-btn>
                                 </v-form>
                                 <!-- Forgot Password -->
                                 <v-row  justify="end" >
                                    <v-col cols="12" sm="5">
                                       <router-link to="/forgot-passwordcopy" class="caption black--text"
                                          style="text-decoration: none;">Forgot Password?
                                       </router-link>
                                    </v-col>
                                 </v-row>
                                 <!-- Error Message -->
                                 <v-col cols="12">
                                    <div v-show="message != null">
                                       <v-alert text prominent  type="error"  icon="mdi-cloud-alert" dismissible>{{ message }}</v-alert>
                                       <!-- <v-alert outlined type="error" prominent border="left" dismissible>
                                          
                                       </v-alert> -->
                                    </div>
                                 </v-col>
                              </v-col>
                           </v-row>
                        </v-card-text>
                     </v-col>
                     <!-- Alumin Sign -->
                     <v-col cols="12" sm="6" class="custom-text-colors">
                        <div style="text-align: center; padding: 60px;">
                          <img src="../assets/logowhite.png" alt="logo" class="log" />
                          <v-card-text class="white--text">
                           <h3>Alumni Sign Up</h3>
                         </v-card-text>
                          <div class="text-center">
                            <router-link to="/Alumin-Signupcopy" class="caption white--text" style="text-decoration: none;">
                              <v-btn tile outlined dark class="caption white--text">Alumni Sign-UP</v-btn>
                            </router-link>
                          </div>
                        </div>
                     </v-col>  
                  </v-row>
               </v-card>
            </v-col>
         </v-row>
      </v-container>
      <div class="text-center">
         <v-dialog v-model="dialog" width="500">
            <v-card>
               <v-card-title class="headline grey lighten-2" primary-title>
                  Privacy Policy
               </v-card-title>
               <v-card-text>
                  Eduplus Campus
               </v-card-text>
               <v-divider></v-divider>
               <v-btn color="primary" text @click="readPolicy()" :loading="read_policy_load">I accept</v-btn>
            </v-card>
         </v-dialog>
      </div>
   </v-app>
</template>

<script>
import axios from "axios";
import crypto from "../service/crypto";
import { required } from "vuelidate/lib/validators";
export default {
   data() {
      return {
         user: {
            uid: "",
            pass: ""
         },
         value: String,
         message: "",
         dialog: false,
         loading: false,
         logo: "",
         read_policy_load: false,
         rules: {
            required: value => !!value || "Required",
            uid: value => {
               const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
               return pattern.test(value) || "Invalied E-Mail.";
            }
         }
      }
   },
   methods: {
      signIn() {
         if (this.perormLoginFormValidation()) {
            let enc = crypto.encrypt(this.user);
            //  console.log("aaaaaa - "+enc)
            this.user.dns = window.location.hostname;
            const data = {
               params: enc
            };
            this.loading = true;
            axios
               .post("/login/process", data)
               .then(res => {
                  if (res.data.msg == "200") {
                     this.loading = false;
                     localStorage.setItem("EPS-token", res.data.token);
                     localStorage.setItem("EPS-uid", crypto.encrypt(res.data.uid));
                     localStorage.setItem("EPS-tenant", crypto.encrypt(res.data.tenant));
                     localStorage.setItem("EPS-usertype", crypto.encrypt(res.data.usertype));
                     if (res.data.policy_read == true) {
                        if(this.user.uid==this.user.pass)
                        {
                          this.$router.push({
                           name: "change_password",
                           params: { uid: this.user.uid,  },
                           });
                        }
                  
                        else
                        this.$router.push("/home");
                     } else {
                        this.dialog = true
                     }
                  } else if (res.data.msg == "superadmin") {
                     this.loading = false;
                     localStorage.setItem("awspath", res.data.awspath);
                     localStorage.setItem("EPS-token", res.data.token);
                     localStorage.setItem("EPS-uid", res.data.uid);
                     //localStorage.setItem("EPS-tenant",res.data.uid);
                     this.$router.push("/admin-supermasters");
                  } else {
                     this.loading = false;
                     this.message = "Please Check Your Credentials...!";
                     localStorage.setItem("EPS-token", "");
                     localStorage.setItem("EPS-uid", "");
                     localStorage.setItem("EPS-tenant", "");
                     localStorage.setItem("awspath", "")
                  }
               })
               .catch(error => {
                  // window.console.log(error); 
                  this.loading = false;
                  this.message = "Something Went Wrong"
               })
         }
         else {
            // console.log("I am in else block");
            this.loading = false;
         }
      },


      readPolicy() {
         this.read_policy_load = true
         axios
            .post("/login/readPolicy", this.user)
            .then(res => {
               //   window.console.log("res" + res.data.msg);
               if (res.data.mes == "200") {
                  this.$router.push("/home");
               }
            })
            .catch(error => {
               window.console.log(error);
               this.message = "Something went wrong";
            })
            .finally(() => {
               this.dialog = false
               this.read_policy_load = false
            });
      },

      clear() {
         this.user.uid = "";
         this.user.pass = "";
      },

      perormLoginFormValidation() {
         if (this.user.uid == "" && this.user.pass == "") {
            return false
         } else {
            return true
         }
      },

      getLogo() {
      var url = window.location.href;
      const data = {
        url: url,
      };
      axios.post("Login/getlogo", data).then((res) => {
        // console.log(res);
        if (res.data.status == "200" && res.data.msg == "success") {
          // console.log(res.data);
          // console.log(res.data.o[0].learner_android_app_link);
          this.logo = res.data.logo;
          
        }
      });
    },//end of function

   },
   mounted() {
      // let enc = crypto.encrypt("reena");
      // console.log("in mounted");
      // console.log(enc);
      this.message = localStorage.getItem("login-page-msg");
      // console.log("login-page-msg>>"+localStorage.getItem("login-page-msg"));

      this.$store.commit('setquick_links', false);
      localStorage.clear();
      this.getLogo();
   }
};
</script>

<style scoped>
.v-application .rounded-bl-xl {
   border-bottom-left-radius: 250px !important;
}

#title {
   background-image: url("../assets/lapt.jpg");
   background-size: cover;
   background-repeat: no-repeat;
   position: relative;
}


.transparent-layer {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(40, 64, 82, 0.466); 
}

.text {
   text-align: center;
   color: white;
   font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   margin-top: 50px;
   position: relative; 
   z-index: 2; 
}

.log {
   margin-bottom: 30px;
   max-width: 210px;
}
.custom-text-color{
   color: darkblue;
   font-size: 24px;
}
.custom-text-colors{
   
   background: linear-gradient(112.6deg, rgb(56, 24, 40) -10.7%, rgb(23, 148, 185) 100.2%);
}
.logi{
   
   background: linear-gradient(to top, #09203f 0%, #537895 100%);
}

.v-carousel .v-carousel-control {
    display: none;
}
</style>
